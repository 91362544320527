import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';
import { useInView } from 'framer-motion';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

import Heading from '../../../molecules/Heading/Heading';

const Slide = ({ content, setCurrentSlide, index, isNumbered }) => {
  const slideRef = useRef();
  const isInView = useInView(slideRef, { amount: 1 });

  useEffect(() => {
    isInView && setCurrentSlide(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <div ref={slideRef}>
      {isNumbered && <Number>{index + 1}</Number>}
      {content && <Heading {...content} />}
    </div>
  );
};

export default Slide;

const Number = styled.p`
  ${font('display', 'xxl', 700)}
  color: ${colors.gray[300]};
  margin-bottom: 24px;
`;

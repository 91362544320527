import React, { useContext } from 'react';

import { isParagraph } from 'datocms-structured-text-utils';
import { renderNodeRule, StructuredText } from 'react-datocms';

import SocialProof from '../../components/Hero/components/HeroSocialProof';

import CallToAction from '../CallToAction/CallToAction';
import { SectionThemeContext } from '../Section/context/SectionContext';

import {
  ButtonWrapper,
  Disclaimer,
  HeadingStyle,
  Kicker,
  Paragraph,
  Subhead,
  Wrapper,
} from './Heading.styles';
import ListItem from './ListItem/ListItem';

const Heading = ({
  heading,
  kicker,
  subhead,
  content,
  alignment,
  kickerTag,
  headingTag,
  headingSize,
  buttons,
  disclaimer,
  includeRatings,
}) => {
  const { theme } = useContext(SectionThemeContext);
  const buttonTheme = {
    primary: theme === 'dark' ? 'secondary' : 'primary',
    secondary: theme === 'dark' ? 'outlineWhite' : 'tertiary',
  };

  const getButtonVariant = (variant, ind) => {
    if (variant === 'brand') return variant;

    return ind % 2 === 1 ? buttonTheme.secondary : buttonTheme.primary;
  };

  return (
    <Wrapper alignment={alignment || 'center'}>
      {kicker && (
        <Kicker as={kickerTag || 'p'} theme={theme || 'light'}>
          {kicker}
        </Kicker>
      )}
      {heading && (
        <HeadingStyle
          as={headingTag || 'h2'}
          theme={theme || 'light'}
          size={headingSize || ['md', 'lg', 'xl']}
        >
          {heading}
        </HeadingStyle>
      )}
      {subhead && (
        <Subhead
          dangerouslySetInnerHTML={{ __html: subhead }}
          theme={theme || 'light'}
        />
      )}
      {content?.value && (
        <StructuredText
          data={content}
          customNodeRules={[
            renderNodeRule(isParagraph, ({ children, key }) => (
              <Paragraph key={key} theme={theme || 'light'}>
                {children}
              </Paragraph>
            )),
          ]}
          renderBlock={({ record }) => {
            switch (record.__typename) {
              case 'DatoCmsBulletPoint':
                return <ListItem data={record} theme={theme || 'light'} />;
              default:
                return null;
            }
          }}
        />
      )}
      {buttons && buttons.length > 0 && (
        <ButtonWrapper>
          {buttons.map((button, ind) => (
            <CallToAction
              key={button?.id}
              variant={getButtonVariant(button?.variant, ind)}
              className={button?.label === 'Chat Now' && 'chat-to-us'}
              value={button?.label}
              link={button?.url}
              popupReference={button?.popupReference}
              icon={button?.icon}
              size={button?.size}
            />
          ))}
        </ButtonWrapper>
      )}
      {disclaimer && (
        <Disclaimer theme={theme || 'light'}>{disclaimer}</Disclaimer>
      )}
      {includeRatings && (
        <SocialProof isCentered={alignment ? alignment === 'centered' : true} />
      )}
    </Wrapper>
  );
};

export default Heading;

import styled from 'styled-components';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { marginX } from '../../styles/atoms/spacing';
import { font } from '../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: ${props =>
    props.alignment === 'center' ? 'center' : 'flex-start'};
  text-align: ${props => (props.alignment === 'center' ? 'center' : 'left')};
  gap: 32px;
  max-width: 770px;
  ${props => props.alignment === 'center' && marginX('auto')}
`;

export const Kicker = styled.p`
  ${font('overline', 'sm', '700')}
  color: ${props =>
    props.theme === 'light' ? colors.primary[500] : colors.base.white};
`;

export const HeadingStyle = styled.h2`
  ${props => font('display', props.size[0], '700')}
  color: ${props =>
    props.theme === 'light' ? colors.gray[900] : colors.base.white};

  ${atMinWidth.sm`
      ${props => font('display', props.size[1], '700')}
  `}

  ${atMinWidth.lg`
      ${props => font('display', props.size[2], '700')}
  `}
`;

export const Subhead = styled.div`
  &, & p {
    ${font('text', 'md', '400')}
    color: ${props =>
      props.theme === 'light' ? colors.gray[600] : colors.gray[50]};

    ${atMinWidth.sm`
        ${font('text', 'lg', '400')}
    `}

    ${atMinWidth.lg`
        ${font('text', 'xl', '400')}
    `}
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-bottom: 3px;

  ${atMinWidth.md`
    flex-direction: row;
    padding-bottom: 0;
  `}
`;

export const Disclaimer = styled.p`
  ${font('text', 'xs', '400')}
  color: ${props =>
    props.theme === 'light' ? colors.gray[600] : colors.gray[50]};

  ${atMinWidth.sm`
      ${font('text', 'sm', '400')}
  `}
`;

export const Paragraph = styled.p`
  ${font('text', 'sm', '400')}
  color: ${props =>
    props.theme === 'light' ? colors.gray[600] : colors.gray[50]};

  ${atMinWidth.sm`
      ${font('text', 'md', '400')}
  `}

  ${atMinWidth.lg`
      ${font('text', 'lg', '400')}
  `}
`;
